export default {
	data() {
		return {
			page: {
				current: 1,
				size: 50,
				sizes: [50, 100, 150, 200],
				total: 0
			},
			trigger: false,
			multipleSelection: [],
			expandedRows: [],
			fetchOnCreated: true,
			isFullPage: false
		}
	},
	created() {
		if (this.fetchOnCreated) {
			this.getList()
		}
	},
	filters: {
		/**
		 * For table expand icon, style is nullable, default `arrow`
		 * support style: arrow, circle
		 * */
		isExpand(row, expandedRows, style) {
			let index = expandedRows.findIndex(v => v.id === row.id)
			switch (style) {
				case 'circle':
					return index === -1 ? 'el-icon-circle-plus-outline' : 'el-icon-remove-outline'
				default:
					return index === -1 ? 'el-icon-arrow-right' : 'el-icon-arrow-down'
			}
		}
	},
	computed: {
		ids() {
			return this.multipleSelection.map(v => v.id)
		}
	},
	methods: {
		showLoading() {
			if (this.adminLayout) {
				this.adminLayout.showLoading(this.isFullPage)
			}
		},
		hideLoading() {
			if (this.adminLayout) {
				this.adminLayout.hideLoading()
			}
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		handleSizeChange(val) {
			this.page.size = val
			this.page.current = 1
			this.getList()
		},
		handleCurrentChange(val) {
			this.page.current = val
			this.getList()
		},
		async getListUrl() {
			
		},
		handleList(content) {

		},
		handlePagable(data) {
			this.page.total = data.totalCount || 0
			if (data.pageable) {
				this.page.current = (data.pageable.page || 0) + 1
			}
		},
		handleRawData(data) {},
		// 查
		getList() {
			this.showLoading()
			this.getListUrl()
			.then(data => {
				this.hideLoading()
				this.handleList(data.content)
				this.handlePagable(data)
				this.handleRawData(data)
			})
			.catch(err => {
				this.hideLoading()
			})
			this.trigger = true
		},
		
		/**
		 * table expand
		 * */
		expandChange(row, expandedRows) {
			this.expandedRows = expandedRows
		},
		toggleRowExpansion(ref, row) {
			if (this.$refs[ref]) {
				this.$refs[ref].toggleRowExpansion(row)
			}
		}
	}
}
