<template>
	<el-card>
		<advanced-search-bar>
			<template>
				<form-item-sites :abbr="true" :multiple="false" :local="true" v-model="queryForm.website"
					:parent="false" @onload="q.website=queryForm.website;searchEvent()" :clearable="false">
				</form-item-sites>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
				</form-item>
			</template>
			<template #advanced>
				<form-item label="广告类型:">
					<el-select v-model="queryForm.type" clearable size="mini">
						<el-option v-for="item in types" :label="item.label" :value="item.value" :key="item.value">
						</el-option>
					</el-select>
				</form-item>
				<form-item label="发布类型:">
					<el-select v-model="queryForm.publishType" clearable size="mini">
						<el-option v-for="item in publishTypes" :label="item.label" :value="item.value"
							:key="item.value"></el-option>
					</el-select>
				</form-item>
				<form-item label="策略ID:">
					<el-input v-model.trim="queryForm.configId" size="mini"></el-input>
				</form-item>
			</template>
		</advanced-search-bar>
		<divider />
		<el-table height="auto" :data="tableData" size="mini">
			<el-table-column label="站点" prop="website" min-width="100" align="center">
			</el-table-column>
			<el-table-column label="广告类型" min-width="100" align="center">
				<template slot-scope="scope">{{scope.row.type|label(types)}}</template>
			</el-table-column>
			<el-table-column label="发布类型" min-width="100" align="center">
				<template slot-scope="scope">{{scope.row.publishType|label(publishTypes)}}</template>
			</el-table-column>
			<el-table-column label="使用策略ID" prop="configId" min-width="150" align="center">
			</el-table-column>
			<el-table-column label="选款数量" prop="productCount" min-width="150" align="center">
			</el-table-column>
			<el-table-column label="搭配数量" prop="matchedCount" min-width="150" align="center">
				<template slot-scope="scope">
					<div @click="showMatchedList(scope.row)" style="cursor:pointer;">{{scope.row.groupCount}}</div>
				</template>
			</el-table-column>
			<el-table-column label="发布广告数量" min-width="150" align="center">
				<template slot-scope="scope">
					<table width="100%">
						<tr>
							<td width="50%">成功{{scope.row.successCount}}</td>
							<td width="50%">失败{{scope.row.failCount}}</td>
						</tr>
					</table>
				</template>
			</el-table-column>
			<el-table-column label="对应发布记录ID" prop="batchNo" min-width="150" align="center">
			</el-table-column>
			<el-table-column label="发布时间" min-width="150" align="center">
				<template slot-scope="scope">
					{{scope.row.createdAt|formatDate}}
				</template>
			</el-table-column>
		</el-table>
		<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
			:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
		</el-pagination>
		<el-dialog :visible.sync="visible.list" width="600px">
			<table border class="match-list" width="100%">
				<template v-for="(item, idx) in details">
					<tr :key="`${idx}`">
						<td width="90%">
							<div v-if="item.pics.length>0" class="match-item-imgs">
								<div class="d-flex flex-row align-items-center" style="width:max-content;">
									<el-image
										:class="{'ml-1': idx2>0}"
										v-for="(pic, idx2) in item.pics" 
										style="width: 80px; height: 80px"
										:src="pic"
										fit="cover"
										:preview-src-list="item.pics"></el-image>
								</div>
							</div>
							<div>
								<small>{{item.ids}}</small>
							</div>
						</td>
						<td width="10%">
							<div class="d-flex flex-column align-items-center">
								<small :class="{'text-danger': !item.active, 'text-success': item.active}">{{item.active ? '匹配成功' : '匹配失败'}}</small>
								<el-button type="text" size="mini" @click="remakePics(item)">重新发布</el-button>
							</div>
						</td>
					</tr>
				</template>
			</table>

		</el-dialog>
	</el-card>
</template>

<script>
	import Common from '@/common/mixins/common.js'
	import {
		mapGetters
	} from 'vuex'
	import Page from '@/common/mixins/page.js'
	export default {
		mixins: [Common, Page],
		inject: ['adminLayout'],
		data() {
			return {
				queryForm: {
					website: '',
					type: '',
					publishType: '',
					configId: ''
				},
				q: {},
				tableData: [],
				namespace: 'ad-strategy',
				fetchOnCreated: false,
				visible: {
					list: false
				},
				details: []
			}
		},
		computed: {
			...mapGetters({
				publishTypes: 'ad-strategy/publishTypes',
				types: 'ad-strategy/types'
			}),
		},
		filters: {
			label(value, list) {
				try {
					if (Array.isArray(value)) {
						return value.map(v => {
							const res = list.find(k => k.value === v)
							return res && res.label
						}).filter(v => v !== null).join(',')
					}
					return list.find(v => v.value === value).label
				} catch (e) {}
			}
		},
		methods: {
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				const payload = {
					params: {
						...this.q,
						...this.page
					},
					trigger
				}
				return this.$store.dispatch(`${this.namespace}/getRecords`, payload)
			},
			handleList(content) {
				this.tableData = content
			},
			searchEvent() {
				this.q = {
					...this.queryForm
				}
				this.getList()
			},
			showMatchedList(row) {
				this.adminLayout.showLoading()
				this.$store.dispatch(`${this.namespace}/getDetails`, row.id)
				.then(data => {
					this.details = (data || []).map(v => {
						const {id, products, matched, pics} = v
						return {
							id,
							pics: (pics || []).map(k => k.image),
							ids: (products || []).map(k => k.spu).join('+'),
							active: matched || false
						}
					})
					this.visible.list = true
				})
				.catch(err => {
					this.$showErrMsg(err)
				})
				.finally(() => {
					this.adminLayout.hideLoading()
				})
			},
			remakePics(item) {
				const {id} = item
				this.adminLayout.showLoading(true)
				this.$store.dispatch(`${this.namespace}/remakePics`, id)
				.then(res => {
					this.$showSuccess('已在重新作图，请稍后刷新查看')
				})
				.catch(err => {
					this.$showErrMsg(err)
				})
				.finally(() => {
					this.adminLayout.hideLoading()
				})
			}
		}
	}
</script>
<style lang="scss">
	table.match-list {
		border-color: #ddd;
		tr {
			td {
				padding: 5px;
			}
			td:nth-child(1) {
				box-sizing: content-box;
			}
		}
	}
	.match-item-imgs {
		width: 490px;
		overflow-x:hidden;
		height: 85px;
		&:hover {
			overflow-x:auto;
		}
		div.el-image {
			border: 1px solid #f3f3f3;
			box-sizing: border-box;
		}
	}
</style>
